import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL, API_DOWNLOAD_URL } from '../config/apiConstants'; // Assuming API_BASE_URL is defined here
import { Box, Typography, Card, CardActionArea , CardContent, Button, Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import SolicitudesCard from './SolicitudesCard';
import ComunicacionesCard from './ComunicacionesCard';
import DocumentosCard from './DocumentosCard';
import ConsultarGestionCard from './ConsultarGestionCard';
import { useDispatch, useSelector } from 'react-redux';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';

import '../../css/Empresas.css';

const ManageEmpresa = () => {
  const [value, setValue] = useState(0);
  const [empresa, setEmpresa] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [pendingCount, setPendingCount] = useState(0);
  const token = useSelector((state) => state.token);

  // Function to handle button clicks for navigation
  const handleItemClick = (path) => {
    navigate(path); // Use navigate to change route
  };

  const handleLiquidaciones = () => {
    navigate('/LiquidacionesToPdf');
  };

  const handleGestionarIndividuales = () => {
    navigate('/DocumentosToPdf');
  };

  const handleGestionarGenerales = () => {
    navigate('/DocumentosGenToPdf');
  };

  const fetchPendingCount = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/solicitudes/all/${token}`); // Replace with your API endpoint
      const resultSol = response.data.filter((s) => s.empresa_id === id && s.status === "1");
      setPendingCount(resultSol.length);
    } catch (error) {
      console.error('Error fetching pending solicitudes count:', error);
    }
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`${API_BASE_URL}/empresas/show/${id}`)
        .then((response) => {
          setEmpresa(response.data);
        })
        .catch((error) => {
          console.error('Error fetching razonSocial:', error);
          setEmpresa('');
        });
    }
    fetchPendingCount();
  }, [id]);

  return (
    <div className="container empresas">
        {/* Display the company title */}
        <Box sx={{ mt: 2 , mb: 2 }}>
          <Typography variant="h4" component="h1">
            {empresa?.NombreFantasia}
          </Typography>
          <Typography variant="h6" component="h2">
            {empresa?.RazonSocial}
          </Typography>
          <br />
         
        </Box>

        {/* Cards as Buttons using Grid */}
        <Grid container >
          {/* Documentos Card */}
          <Grid item xs={12} md={12} >
            <Typography variant="h5" component="div">
              ¿Qué quieres realizar?
            </Typography>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2 }}>
              <Card sx={{ backgroundColor: '#FFFFFF', flexGrow: 1, mx: 1, height: '200px', display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="div">
                    Liquidaciones de sueldo
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Sube archivos PDF con liquidaciones de sueldo.
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, ml: 3 }}>
                  <Button 
                    variant="contained" 
                    className="crear-empresa-btn" 
                    startIcon={<AttachMoneyIcon />}
                    onClick={handleLiquidaciones}
                  >
                    Subir Liquidaciones
                  </Button>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2 }}>
              <Card sx={{ backgroundColor: '#FFFFFF', flexGrow: 1, mx: 1, height: '200px', display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="div">
                    Documentos Individuales
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Sube contratos, anexos y otros documentos individuales.
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, ml: 3 }}>
                  <Button 
                    variant="contained" 
                    className="crear-empresa-btn" 
                    startIcon={<DescriptionIcon />}
                    onClick={handleGestionarIndividuales}
                  >
                    Subir Documentos
                  </Button>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2 }}>
              <Card sx={{ backgroundColor: '#FFFFFF', flexGrow: 1, mx: 1, height: '200px', display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="div">
                    Documentos Generales
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Sube documentos que se asignan simultáneamente a todos los trabajadores.
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, ml: 3 }}>
                  <Button 
                    variant="contained" 
                    className="crear-empresa-btn" 
                    startIcon={<FolderIcon />}
                    onClick={handleGestionarGenerales}
                  >
                    Subir Documentos
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Grid>

          {/* Consultar Documentos y Gestión de Trabajos Card */}
          <Grid item xs={12} md={12} sx={{ mt: 2 , mb: 2 }}>
            <Typography variant="h5" sx={{ color: 'black' }}>
              Consultar Datos
            </Typography>
            <ConsultarGestionCard empresaId={empresa?.id} />
          </Grid>

          {/* Solicitudes Card */}
          <Grid item xs={12} md={4} >
            <Card sx={{ backgroundColor: '#E8F5E9', flexGrow: 1, mx: 1 }}>
              <>
                <CardContent>
                  <Typography variant="h6" sx={{ color: 'black' }}>Solicitudes</Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>              
                    Tienes {pendingCount} solicitud(es) por revisar
                  </Typography>
                  <br/>
                  <Button
                    variant="contained"
                    className="crear-empresa-btn"
                    sx={{ mt: 2 }}
                    onClick={() => handleItemClick(`/SolicitudesCard`)} // Adjust path as per routing
                  >
                    Gestionar Solicitudes
                  </Button>
                </CardContent>
              </>
            </Card>
          </Grid>
          {/* Comunicaciones Card */}
          <Grid item xs={12} md={4}>
            <Card sx={{ backgroundColor: '#fafafa', flexGrow: 1, mx: 1 }}>
              <>
                <CardContent>
                  <Typography variant="h6" sx={{ color: 'black' }}>Comunicaciones</Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>Publica las últimas noticias sobre tu establecimiento</Typography>
                  <br/>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => handleItemClick(`/ComunicacionesCard`)} // Adjust path as per routing
                  >
                    Publicar Comunicación
                  </Button>
                </CardContent>
              </>
            </Card>
          </Grid>
          {/* Ley Karin Card */}
          {!["11", "8", "12", "13"].includes(id) && (
            <Grid item xs={12} md={4}>
              <Card sx={{ backgroundColor: '#fafafa', flexGrow: 1, mx: 1 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ color: 'black' }}>
                    Denuncias Ley Karin
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Revisa las últimas denuncias con respecto a esta ley
                  </Typography>
                  <br />
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => handleItemClick(`/LeyKarin`)} // Adjust path as per routing
                  >
                    Revisar Denuncias
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
    </div>
  );
};

export default ManageEmpresa;
