import React from 'react';
import { Grid, Typography, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import MoneyIcon from '@mui/icons-material/Money';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BeachAccess from '@mui/icons-material/BeachAccess';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const DocumentosCard = ({ usuario }) => {
    const empresaId = useSelector((state) => state.empresaId); // Assuming empresaId is stored in Redux
    const navigate = useNavigate();

    const handleSolicitarAnticipo = () => {
        navigate('/SolicitarAnticipo');
    };

    const handleSolicitarPrestamo = () => {
        navigate('/SolicitarPrestamo');
    };

    const handleSolicitarPermiso = () => {
        navigate('/SolicitarPermiso');
    };

    const handleSolicitarVacaciones = () => {
        navigate('/SolicitarVacaciones');
    };

    return (
    <List>
      <Grid container spacing={2}>
        {/* Contratos y Anexos */}
        <Grid item xs={6} sm={6} md={3}>
            <ListItem
                button
                sx={{
                    height: '84px', 
                    border: '1px solid rgba(63, 81, 181, 0.3)', // Borde azul con 50% de transparencia
                    borderRadius: '4px',          // Añade esquinas redondeadas
                    padding: '10px 15px',         // Agrega padding para darle apariencia de botón
                    marginBottom: '10px',         // Agrega margen inferior para separación
                    '&:hover': {
                      backgroundColor: '#fafafa', // Cambia el color de fondo al pasar el mouse
                    },
                  }}
                onClick={handleSolicitarAnticipo}

            >   
           <ListItemIcon>
                <Box
                  sx={{
                    borderRadius: '50%',            // Hacer el contenedor circular
                    border: '2px solid #E0F2F1',    // Borde de color #E0F2F1
                    backgroundColor: '#E0F2F1',     // Fondo del color #E0F2F1
                    padding: '8px',                 // Espaciado dentro del círculo
                    display: 'flex',                // Centrar el ícono
                    justifyContent: 'center',       // Centrar horizontalmente
                    alignItems: 'center',           // Centrar verticalmente
                  }}
                > 
                    <img 
                    src="/cards/005.png"    // Ruta del archivo PNG
                    alt="Anticipo" 
                    style={{ width: '24px', height: '24px' }}  // Tamaño del ícono
                  />              
                </Box>
           </ListItemIcon>
           <ListItemText primary="Anticipo" />
          </ListItem>
        </Grid>

        {/* Liquidaciones */}
        {!["11", "12"].includes(empresaId) && (
          <Grid item xs={6} sm={6} md={3}>
              <ListItem
                  button
                  sx={{
                      height: '84px', 
                      border: '1px solid rgba(63, 81, 181, 0.3)', // Borde azul con 50% de transparencia
                      borderRadius: '4px',          // Añade esquinas redondeadas
                      padding: '10px 15px',         // Agrega padding para darle apariencia de botón
                      marginBottom: '10px',         // Agrega margen inferior para separación
                      '&:hover': {
                        backgroundColor: '#fafafa', // Cambia el color de fondo al pasar el mouse
                      },
                    }}
                  onClick={handleSolicitarPrestamo}
              >   
            <ListItemIcon>
                  <Box
                    sx={{
                      borderRadius: '50%',            // Hacer el contenedor circular
                      border: '2px solid #E0F2F1',    // Borde de color #E0F2F1
                      backgroundColor: '#E0F2F1',     // Fondo del color #E0F2F1
                      padding: '8px',                 // Espaciado dentro del círculo
                      display: 'flex',                // Centrar el ícono
                      justifyContent: 'center',       // Centrar horizontalmente
                      alignItems: 'center',           // Centrar verticalmente
                    }}
                  > 
                      <img 
                      src="/cards/006.png"    // Ruta del archivo PNG
                      alt="Préstamo" 
                      style={{ width: '24px', height: '24px' }}  // Tamaño del ícono
                    />              
                  </Box>
              </ListItemIcon>
              <ListItemText primary="Préstamo" />
            </ListItem>
          </Grid>
        )}
        {/* Reglamentos */}
        <Grid item xs={6} sm={6} md={3}>
            <ListItem
                button
                sx={{
                    height: '84px', 
                    border: '1px solid rgba(63, 81, 181, 0.3)', // Borde azul con 50% de transparencia
                    borderRadius: '4px',          // Añade esquinas redondeadas
                    padding: '10px 15px',         // Agrega padding para darle apariencia de botón
                    marginBottom: '10px',         // Agrega margen inferior para separación
                    '&:hover': {
                      backgroundColor: '#fafafa', // Cambia el color de fondo al pasar el mouse
                    },
                  }}
                onClick={handleSolicitarPermiso}
            >   
           <ListItemIcon>
                <Box
                  sx={{
                    borderRadius: '50%',            // Hacer el contenedor circular
                    border: '2px solid #E0F2F1',    // Borde de color #E0F2F1
                    backgroundColor: '#E0F2F1',     // Fondo del color #E0F2F1
                    padding: '8px',                 // Espaciado dentro del círculo
                    display: 'flex',                // Centrar el ícono
                    justifyContent: 'center',       // Centrar horizontalmente
                    alignItems: 'center',           // Centrar verticalmente
                  }}
                > 
                    <img 
                    src="/cards/007.png"    // Ruta del archivo PNG
                    alt="Permiso" 
                    style={{ width: '24px', height: '24px' }}  // Tamaño del ícono
                  />              
                </Box>
               </ListItemIcon>
              <ListItemText primary="Permiso" />
          </ListItem>
        </Grid>

        {/* Otros */}
        <Grid item xs={6} sm={6} md={3}>
            <ListItem
                sx={{
                    height: '84px', 
                    border: '1px solid rgba(63, 81, 181, 0.3)', // Borde azul con 50% de transparencia
                    borderRadius: '4px',          // Añade esquinas redondeadas
                    padding: '10px 15px',         // Agrega padding para darle apariencia de botón
                    marginBottom: '10px',         // Agrega margen inferior para separación
                    '&:hover': {
                      backgroundColor: '#fafafa', // Cambia el color de fondo al pasar el mouse
                    },
                  }}
                onClick={handleSolicitarVacaciones}
            >   
           <ListItemIcon>
                <Box
                  sx={{
                    borderRadius: '50%',            // Hacer el contenedor circular
                    border: '2px solid #E0F2F1',    // Borde de color #E0F2F1
                    backgroundColor: '#E0F2F1',     // Fondo del color #E0F2F1
                    padding: '8px',                 // Espaciado dentro del círculo
                    display: 'flex',                // Centrar el ícono
                    justifyContent: 'center',       // Centrar horizontalmente
                    alignItems: 'center',           // Centrar verticalmente
                  }}
                >  
                <img 
                src="/cards/008.png"    // Ruta del archivo PNG
                alt="Beneficios" 
                style={{ width: '24px', height: '24px' }}  // Tamaño del ícono
              />              
            </Box>
           </ListItemIcon>
           <ListItemText primary="Beneficios" />
          </ListItem>
        </Grid>
      </Grid>
    </List>
  );
};

export default DocumentosCard;
